function NewQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "bool": {
        "should": [
          {
            "match": {
              "label.strip_whitespace": {
                "query": searchTerm,
                "boost": 100,
                "_name": "exact match * score_multiply_100"
              }
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_3_chars_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 90,
              "_name": "ART and (HUR) * score_multiply_95"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_3_chars_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_plus_s"
                    ]
                  }
                }
              ],
              "boost": 90,
              "_name": "ART and (URS) * score_multiply_90"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_two_chars"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 80,
              "_name": "AR and (HUR) * score_multiply_80"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_3_chars_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars",
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 70,
              "_name": "ART and (UR) * score_multiply_70"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_double"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 60,
              "_name": "AA and (HUR) * score_multiply_60"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_two_chars"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_double_last"
                    ]
                  }
                }
              ],
              "boost": 15,
              "_name": "AR and (URR) * score_multiply_25"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_two_chars"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_plus_s"
                    ]
                  }
                }
              ],
              "boost": 15,
              "_name": "AR and (URS) * score_multiply_15"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_two_chars"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars_drop_last_keep_first_two_for5chars"
                    ]
                  }
                }
              ],
              "boost": 15,
              "_name": "AR and (HU*) * score_multiply_15"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char",
                      "label.second_char"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 15,
              "_name": "(*A or A*)  and (HUR) * score_multiply_15"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.transform*"
                    ]
                  }
                }
              ],
              "boost": 10,
              "_name": "transform * score_multiply_10"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_double",
                      "label.first_two_chars"
                    ]
                  }
                }
              ],
              "boost": 15,
              "_name": "transform AND (AR or AA) * score_multiply_15"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 20,
              "_name": "transform AND (HUR) * score_multiply_20"
            }
          },
          {
            "match": {
              "label.ngram": {
                "query": searchTerm,
                "boost": 10,
                "_name": "wildcard * score_multiply_10"
              }
            }
          },
          {
            "term": {
              "supplier_id": {
                "value": 19,
                "boost": 10,
                "_name": "supplier_id 19 * score_multiply_10"
              }
            }
          }
        ],
        "filter": [
          {
            "term": {
              "available": "true"
            }
          }
        ],
        "minimum_should_match": 1
      }
    },
    "_source": ["label"],
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "track_total_hits": true // Add this line to track total hits
  };
}

export default NewQuery;
