import React, { useEffect, useState } from "react";
import axios from "axios";

const useESQuery = (endpoint, query) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const esQuery = async (endpoint, query) => {
    setIsLoading(true);
    setError(null);

    const url = new URL(endpoint);
    const auth =
      url.username && url.password
        ? { username: url.username, password: url.password }
        : null;

    let index =
      url.pathname === "/" ? "4chars" : url.pathname.replace(/\//g, "");
    const esSearchEndpoint = `${url.origin}/${index}/_search`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (auth) {
      config.auth = auth;
    }

    try {
      const response = await axios.post(esSearchEndpoint, query, config);

      setData(response.data);
    } catch (e) {
      setError(e.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (endpoint && query) {
        esQuery(endpoint, query);
      }
    }, 250);

    return () => clearTimeout(timeOutId);
  }, [endpoint, query]);

  return { data, isLoading, error };
};

export default useESQuery;
