import React, { useEffect, useState } from "react";

const useLocalStorage = (key, initialValue) => {
  const [item, setItem] = useState(initialValue);

  useEffect(() => {
    try {
      const storedItem = localStorage.getItem(key);

      if (!storedItem) {
        localStorage.setItem(key, JSON.stringify(initialValue));
      } else {
        setItem(JSON.parse(storedItem));
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [key]);

  const setter = (newItem) => {
    localStorage.setItem(key, JSON.stringify(newItem));
    setItem(newItem);
  };

  return [item, setter];
};

export default useLocalStorage;
