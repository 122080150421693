import React, { useEffect, useState } from "react";
import Grid from "./Grid";
import Modal from "./Modal";

const App = ({ onOldQuery, onNewQuery, endpointName, headerText }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [newQuery, setNewQuery] = useState("");
  const [oldQuery, setOldQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const labelsPerPage = 20;

  const handleNewData = (count) => {
    setTimeout(() => {
      setTotalPages((prev) => Math.max(prev, count));
    }, 250);
  };

  const handleNewSearch = (pageNumber) => {
    if (searchTerm) {
      setNewQuery(onNewQuery(searchTerm, pageNumber, labelsPerPage));
      setOldQuery(onOldQuery(searchTerm, pageNumber, labelsPerPage));
    }
  };

  const handleLabelClick = (details) => {
    setSelectedLabel(details);
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    handleNewSearch(pageNumber);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setCurrentPage(1);
      handleNewSearch(1);
    }, 1000);

    return () => clearTimeout(timeOutId);
  }, [searchTerm,  location.pathname]);


  return (
    <>
      <h2 className="page-title">{headerText}</h2>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by label..."
        className="input search-input"
      />
      <div className="labels-container">
        <div className="labels">
          <h3 className="title">New Query</h3>
          <Grid
            localStorageKey={`new_${endpointName}`}
            query={newQuery}
            currentPage={currentPage}
            labelsPerPage={labelsPerPage}
            onData={handleNewData}
            onLabelClick={handleLabelClick}
          />
        </div>
        <div className="labels">
          <h3 className="title">Old Query</h3>
          <Grid
            localStorageKey={`old_${endpointName}`}
            query={oldQuery}
            currentPage={currentPage}
            labelsPerPage={labelsPerPage}
            onData={handleNewData}
            onLabelClick={handleLabelClick}
          />
        </div>
      </div>
      <div className="pagination-container">
        {Array.from({ length: Math.min(totalPages, 10) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePaginationClick(index + 1)}
            className={`pagination-button ${
              currentPage === index + 1 ? "active" : ""
            }`}
          >
            {index + 1}
          </button>
        ))}
        {totalPages > 10 && currentPage <= totalPages - 10 && (
          <button
            onClick={() => handlePaginationClick(currentPage + 1)}
            className="show-more-button"
          >
            Show More
          </button>
        )}
      </div>
      {selectedLabel && (
        <Modal details={selectedLabel} onClose={() => setSelectedLabel(null)} />
      )}
    </>
  );
};

export default App;
