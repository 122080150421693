import React, { useEffect, useRef } from "react";

const Modal = ({ details, onClose }) => {
  const modalRef = useRef(null);

  const handleKeyPress = ({ key }) => {
    if (key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    modalRef.current.focus();
  }, []);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        tabIndex={0}
        onKeyDown={(e) => handleKeyPress(e)}
        ref={modalRef}
      >
        <h2>{details.label}</h2>
        <h3>Matched Queries:</h3>
        {details.matchedQueries && (
          <ul className="matched-queries-list">
            {details.matchedQueries.map((query, index) => (
              <li key={index}>{query}</li>
            ))}
          </ul>
        )}
        <button className="close-modal-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
