import React, { useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import useESQuery from "../hooks/useESQuery";

const Grid = ({
  localStorageKey,
  query,
  currentPage,
  labelsPerPage,
  onData,
  onLabelClick,
}) => {
  const [endpoint, setEndpoint] = useLocalStorage(localStorageKey, "");
  const { data, isLoading, error } = useESQuery(endpoint, query);
  const [total, setTotal] = useState(0);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (data) {
      const totalLabels = data.hits.total.value;
      const newLabels = data.hits.hits.map((hit, index) => ({
        id: index + 1 + (currentPage - 1) * labelsPerPage,
        label: hit._source.label,
        details: {
          index: hit._index,
          type: hit._type,
          id: hit._id,
          score: hit._score,
          label: hit._source.label,
          matchedQueries: hit.matched_queries,
        },
      }));

      setTotal(totalLabels);
      setLabels(newLabels);
      onData(Math.ceil(totalLabels / labelsPerPage));
    }
  }, [data]);

  return (
    <>
      <input
        type="text"
        value={endpoint}
        onChange={(e) => setEndpoint(e.target.value)}
        placeholder="http://elastic:password@localhost:9200"
        className="input endpoint-input"
      />
      {isLoading && <div className="loader"></div>}
      {!isLoading && error && <p className="error-msg">{error}</p>}
      {!isLoading && !error && data && (
        <div>
          <p className="total-labels-text">{total} labels found</p>
          <div className="grid">
            {labels.map((item) => (
              <button
                key={item.id}
                className="label-item"
                onClick={() => onLabelClick(item.details)}
              >
                {item.id}. {item.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Grid;
