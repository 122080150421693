function OldQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "bool": {
        "should": [
          {
            "match": {
              "label.first_2_chars_mapping_field": {
                "query": searchTerm,
                "boost": 5
              }
            }
          },
          {
            "match": {
              "label.last_3_chars_mapping_field": {
                "query": searchTerm,
                "boost": 2.5
              }
            }
          },
          {
            "bool": {
              "must": [
                {
                  "simple_query_string": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_mapping_field",
                      "label.2nd_part_first_two_chars_mapping_field"
                    ]
                  }
                },
                {
                  "simple_query_string": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_part_mapping_field",
                      "label.first_part_convert_chars_mapping_field"
                    ]
                  }
                }
              ],
              "boost": 4
            }
          },
          {
            "match": {
              "label.last_2_chars_mapping_field": {
                "query": searchTerm,
                "boost": 5
              }
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_2_chars_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.last_3_chars_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.last_char_twice_repeat_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.2nd_part_first_two_chars_mapping_field": searchTerm
                  }
                }
              ],
              "boost": 4
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_2_chars_mapping_field": searchTerm
                  }
                }
              ],
              "should": [
                {
                  "match": {
                    "label.last_2_chars_index_search_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.2nd_part_first_two_chars_mapping_field": searchTerm
                  }
                }
              ],
              "boost": 6.5
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char_twice_repeating_mapping_field": searchTerm
                  }
                }
              ],
              "should": [
                {
                  "match": {
                    "label.last_2_chars_index_search_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.2nd_part_first_two_chars_mapping_field": searchTerm
                  }
                }
              ],
              "boost": 5.5
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.firstpart_first3_last3_chars_mapping_field": searchTerm
                  }
                },
                {
                  "match": {
                    "label.last_3_chars_mapping_field": searchTerm
                  }
                }
              ],
              "boost": 4
            }
          },
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "label",
                "label.no_numbers_mapping_field"
              ],
              "type": "best_fields",
              "analyzer": "multi_word_search_current_style_analyzer",
              "boost": 5
            }
          },
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "label",
                "label.no_numbers_mapping_field"
              ],
              "type": "best_fields",
              "analyzer": "multi_word_number_sub_search_current_style_analyzer"
            }
          },
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "label",
                "label.no_numbers_mapping_field",
                "label.mapped_single_numbers1_mapping_field",
                "label.mapped_single_numbers2_mapping_field"
              ],
              "type": "best_fields",
              "analyzer": "base_search_current_style_analyzer"
            }
          },
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "label",
                "label.no_numbers_mapping_field"
              ],
              "analyzer": "bigram_current_style_search_analyzer",
              "boost": 0.2
            }
          },
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "label",
                "label.no_numbers_mapping_field"
              ],
              "analyzer": "base_trigram_search_current_style_analyzer",
              "boost": 1.5
            }
          },
          {
            "match": {
              "label.mapped_single_char_mapping_field": {
                "query": searchTerm,
                "analyzer": "single_char_mapping_long_ngram_current_style_search_analyzer"
              }
            }
          },
          {
            "match": {
              "label.mapped_single_char_mapping_field": {
                "query": searchTerm,
                "analyzer": "single_char_mapping_trigram_current_style_search_analyzer"
              }
            }
          },
          {
            "match": {
              "label.mapped_single_char_mapping_field": {
                "query": searchTerm,
                "analyzer": "single_char_mapping_current_style_analyzer"
              }
            }
          },
          {
            "match": {
              "label.mapped_multicharacters_mapping_field": {
                "query": searchTerm,
                "analyzer": "base_search_current_style_analyzer",
                "boost": 1.75
              }
            }
          },
          {
            "match": {
              "label.single_char_ngram_mapping_field": {
                "query": searchTerm,
                "boost": 1
              }
            }
          },
          {
            "match": {
              "supplier_id": {
                "query": 19,
                "boost": 8,
                "lenient": true,
                "operator": "OR",
                "zero_terms_query": "all"
              }
            }
          }
        ],
        "filter": [
          {
            "term": {
              "available": true
            }
          }
        ]
      }
    },
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "sort": [
      "_score"
    ],
    "track_total_hits": true
  };
};

export default OldQuery;
